import { z } from 'zod'

const TeamObjectiveValueSchema = z.object({
  id: z.string().uuid().optional(),
  value: z.number().nullish(),
  startDate: z.string(),
  weekNumber: z.number().int().positive(),
})

export const TeamObjectiveSchema = z.object({
  id: z.string().uuid().optional(),
  title: z.string().min(2).max(50),
  type: z.enum(['average', 'sum']),
  target: z.number(),
  owners: z.array(z.string()),
  values: z.array(TeamObjectiveValueSchema),
  year: z.number().int().positive(),
  quarter: z.number().int().min(1).max(4),
})
