import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { UserButton, useAuth } from '@clerk/clerk-react'
import type { TawkAPI } from '@/types/Tawk'

import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { Icon, IconName } from '@/components/ui/icon'
import { LogoLink } from '@/components/common/logo'

import { MOCK_SERVER_ENABLED } from '@/services/config/env'

import { cn } from '@/lib/utils'
import { CannyOptions } from '@/types/Canny'

interface MenuOption {
  label: string
  href: string
  icon: IconName
  disabled?: boolean
}

const menuOptions: MenuOption[] = [
  {
    label: 'Dashboard',
    icon: 'LayoutDashboard',
    href: '/',
  },
  {
    label: 'Feedback Points',
    icon: 'TrendingUp',
    href: '/feedback-points',
  },
  {
    label: 'Feedback Sessions',
    icon: 'Star',
    href: '/feedback-sessions',
    disabled: !MOCK_SERVER_ENABLED,
  },
  {
    label: 'Objectives',
    icon: 'Crosshair',
    href: '/objectives',
  },
]

const MenuOptionItem = ({ label, href, icon, disabled }: MenuOption) => {
  const { pathname } = useLocation()

  return (
    <Link
      key={label}
      to={!disabled ? href : '#'}
      className={cn(
        'flex items-center gap-3 rounded-xl py-3 px-4 text-muted-foreground transition-all hover:opacity-75',
        pathname === href && 'text-white bg-green-600 hover:opacity-100',
        disabled && 'opacity-50 hover:opacity-50',
      )}
    >
      <Icon name={icon} />
      {label}
    </Link>
  )
}

const LogoutButton = () => {
  const { signOut } = useAuth()

  return (
    <div className="flex items-center gap-2">
      <UserButton afterSignOutUrl="/sign-in" />
      <Button
        variant="link"
        className="flex gap-2"
        onClick={() => signOut({ redirectUrl: '/sign-in' })}
      >
        <Icon name="LogOut" />
        Log Out
      </Button>
    </div>
  )
}

const SupportButton = ({ onClick }: { onClick: () => void }) => (
  <div
    className="flex items-center gap-3 rounded-xl py-3 px-4 text-muted-foreground transition-all hover:opacity-75 cursor-pointer text-sm font-medium"
    onClick={onClick}
  >
    <Icon name="CircleHelp" />
    Support
  </div>
)

const ChangelogButton = () => (
  <div
    className="flex items-center gap-3 rounded-xl py-3 px-4 text-muted-foreground transition-all hover:opacity-75 cursor-pointer text-sm font-medium"
    data-canny-changelog
  >
    <Icon name="Inbox" />
    Changelog
  </div>
)

const CannyChangelog = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.Canny) {
      window.Canny('initChangelog', {
        appID: '66ad67a82ec2f62f925c7e66',
        position: 'top',
        align: 'left',
        theme: 'light',
      } as CannyOptions)
    }
  }, [])

  return null
}

export const MenuDesktop = () => {
  const toggleTawk = () => {
    const tawkApi = window.Tawk_API as TawkAPI | undefined;
    if (tawkApi && typeof tawkApi.toggle === 'function') {
      tawkApi.toggle();
    }
  };

  return (
    <div className="hidden border-r bg-white md:block">
      <div className="flex h-full max-h-screen flex-col">
        <div className="flex items-center px-6 py-4 border-b">
          <LogoLink size="normal" />
        </div>
        <div className="flex-1 flex flex-col justify-between py-6">
          <nav className="flex flex-col px-6 text-sm font-medium gap-1">
            {menuOptions.map((option) => (
              <MenuOptionItem key={option.label} {...option} />
            ))}
          </nav>
          <div className="px-6 mt-auto space-y-4">
            <ChangelogButton />
            <SupportButton onClick={toggleTawk} />
            <LogoutButton />
          </div>
        </div>
      </div>
      <CannyChangelog />
    </div>
  )
}

export const MenuMobile = () => {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="shrink-0 md:hidden text-green-600"
        >
          <Icon name="Menu" className="h-5 w-5" />
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="flex flex-col">
        <nav className="grid gap-2 text-lg font-medium">
          <LogoLink size="normal" className="mb-8" />
          {menuOptions.map((option) => (
            <MenuOptionItem key={option.label} {...option} />
          ))}
        </nav>
      </SheetContent>
    </Sheet>
  )
}
