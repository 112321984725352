import { Outlet } from 'react-router-dom'

import { MenuDesktop, MenuMobile } from '@/components/menu'

export const Layout = () => (
  <div className="flex-1 w-full md:grid-cols-[280px_1fr] md:grid">
    <MenuDesktop />
    <header className="fixed top-0 w-full flex h-13 items-center gap-4 bg-white border-b bg-muted/40 p-4 md:hidden">
      <MenuMobile />
    </header>
    <div className="flex flex-col overflow-y-auto mt-[69px] max-h-[calc(100vh-69px)] md:max-h-none md:mt-0">
      <main className="flex flex-1 p-6 sm:p-8">
        <Outlet />
      </main>
    </div>
  </div>
)
