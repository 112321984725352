import { TeamUser } from '@/types/UserProfile'

export const formatDecimalToPercentage = (value: number, decimalPlaces = 2) => {
  if (Number.isNaN(value)) {
    return '--'
  }

  return `${numberWithCommas(value * 100, decimalPlaces)}%`
}

export function numberWithCommas(value?: number | string, decimalPlaces = 2) {
  if (value == undefined) {
    return '--'
  }

  const options = {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }
  return Number(value).toLocaleString('us', options)
}

export const toProperCase = (txt: string) => {
  return txt.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const getUserAvatarFallback = (user: TeamUser) => {
  return `${user.first_name?.[0] ?? ''}${user.last_name?.[0] ?? ''}`
}
