import axios from 'axios'

import { MOCK_SERVER_ENABLED } from '@/services/config/env'

const BASE_API_URL = !MOCK_SERVER_ENABLED
  ? import.meta.env.VITE_API_URL
  : import.meta.env.VITE_MOCK_API_URL

export const client = axios.create({
  baseURL: BASE_API_URL,
})
