import { SetState } from '@/types/globals'

import { Dialog, DialogContent } from '@/components/ui/dialog'

interface ModalProps {
  isOpen: boolean
  setIsOpen: SetState<boolean>
  children: JSX.Element
}

export const Modal = ({ isOpen, setIsOpen, children }: ModalProps) => (
  <Dialog open={isOpen} onOpenChange={setIsOpen}>
    <DialogContent className="sm:max-w-[725px] max-h-[80vh] overflow-hidden flex flex-col">
      <div className="overflow-y-auto flex-grow pr-4">{children}</div>
    </DialogContent>
  </Dialog>
)
