import { useEffect, useState } from 'react'

import { AvatarWithFallback } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Check } from 'lucide-react'
import { Icon } from '@/components/ui/icon'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'

import { getUserAvatarFallback } from '@/services/utils/formatters'
import { useFetchTeamMembers } from '@/services/api/team.api'
import { cn } from '@/lib/utils'
import { TeamUser } from '@/types/UserProfile'
import { Tooltip } from './common/tooltip'
import { ClassName } from '@/types/globals'

interface TeamMemberSelectorProps {
  onChange: (_selected: TeamUser[]) => void
  defaultValue?: string[]
}

export const TeamMemberSelector = ({
  onChange,
  defaultValue = [],
}: TeamMemberSelectorProps) => {
  const [open, setOpen] = useState(false)
  const { data: teamUsers } = useFetchTeamMembers()
  const [selected, setSelected] = useState<TeamUser[]>([])

  const handleClickAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setOpen(true)
  }

  const handleDelete = (user: TeamUser) => {
    setSelected((prev) => {
      const newValue = prev.some((u) => u.id === user.id)
        ? prev.filter((u) => u.id !== user.id)
        : prev

      onChange(newValue)
      return newValue
    })
  }

  const handleAdd = (user: TeamUser) => {
    setSelected((prev) => {
      const newValue = prev.some((u) => u.id === user.id)
        ? prev.filter((u) => u.id !== user.id)
        : [...prev, user]

      onChange(newValue)
      return newValue
    })
    setOpen(false)
  }

  useEffect(() => {
    setSelected(teamUsers.filter((u) => defaultValue.includes(u.id)))
  }, [defaultValue, teamUsers])

  return (
    <div className="flex gap-2 items-center">
      <TeamMemberList
        users={selected}
        handleClick={(e, user) => {
          e.preventDefault()
          handleDelete(user)
        }}
      />
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button variant="ghost" size="icon" onClick={handleClickAdd}>
            <Icon name="Plus" className="h-4 w-4 text-green-600" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0 z-50">
          <Command>
            <CommandInput placeholder="Search ..." />
            <CommandEmpty>No item found.</CommandEmpty>
            <CommandGroup className="max-h-64 overflow-auto">
              <CommandList>
                {teamUsers.map((user) => (
                  <CommandItem
                    key={crypto.randomUUID()}
                    onSelect={() => handleAdd(user)}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        selected.some((u) => u.id === user.id)
                          ? 'opacity-100'
                          : 'opacity-0',
                      )}
                    />
                    {user.first_name}
                  </CommandItem>
                ))}
              </CommandList>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}

interface TeamMemberListProps {
  users: TeamUser[]
  handleClick?: (
    _e: React.MouseEvent<HTMLButtonElement>,
    _user: TeamUser,
  ) => void
  className?: ClassName
}

export const TeamMemberList = ({
  users,
  handleClick,
  className,
}: TeamMemberListProps) => {
  return (
    <div className="flex gap-1">
      {users.map((user) => (
        <Tooltip key={crypto.randomUUID()} content={user.first_name}>
          <button onClick={(e) => handleClick && handleClick(e, user)}>
            <AvatarWithFallback
              image={user.image}
              fallback={getUserAvatarFallback(user)}
              className={cn('border-green-600 border-2', className)}
            />
          </button>
        </Tooltip>
      ))}
    </div>
  )
}
