import { useEffect, useMemo, useState } from 'react'
import { useAuth, useUser } from '@clerk/clerk-react'
import { Outlet, useNavigate } from 'react-router-dom'
import posthog from 'posthog-js'

import { Loading } from '@/components/ui/loading'
import { MOCK_SERVER_ENABLED } from '@/services/config/env'

export const ProtectedPage = () => {
  const { isLoaded, isSignedIn, getToken } = useAuth()
  const { user } = useUser()
  const navigate = useNavigate()
  const [isLoadingToken, setIsLoadingToken] = useState(true)
  const isLoading = useMemo(() => {
    if (MOCK_SERVER_ENABLED) {
      return false
    }

    return isLoadingToken || !isLoaded
  }, [isLoadingToken, isLoaded])

  useEffect(() => {
    if (MOCK_SERVER_ENABLED) {
      return
    }

    if (isLoaded && !isSignedIn) {
      navigate('/sign-in')
    } else if (isLoaded && isSignedIn) {
      setIsLoadingToken(false)
    }
  }, [isSignedIn, isLoaded, navigate, getToken, user])

  useEffect(() => {
    if (user) {
      return posthog.identify(user.id, {
        email: user.primaryEmailAddress?.emailAddress,
        firstName: user.firstName,
        lastName: user.lastName,
        image: user.imageUrl,
      })
    }
    posthog.identify(undefined)
  }, [user])

  return (
    <>
      {isLoading && (
        <Loading className="absolute top-1/2 left-1/2 size-12 text-green-600" />
      )}
      {((isSignedIn && !isLoading) || MOCK_SERVER_ENABLED) && <Outlet />}
    </>
  )
}
