import { SignIn } from '@clerk/clerk-react'

export function SignInPage() {
  return (
    <div className="w-full flex justify-center items-center">
      <SignIn
        path="/sign-in"
        appearance={{
          elements: {
            footerAction: { display: 'none' }, // disabling the sign-up here for now
          },
        }}
      />
    </div>
  )
}
