import { useAuth } from '@clerk/clerk-react'
import { useQuery } from '@tanstack/react-query'

import { fetchResolve } from './helpers'

import { FeedbackStats } from '@/types/FeedbackStats'
import { FeedbackPointWithProfile } from '@/types/FeedbackPoint'

export const useFetchFeedbackPoints = () => {
  const { getToken } = useAuth()
  return useQuery<FeedbackPointWithProfile[]>({
    queryKey: ['useFetchFeedbackPoints'],
    queryFn: () => fetchResolve(`/feedback/point`, getToken),
  })
}

export const useFetchFeedbackStats = () => {
  const { getToken } = useAuth()
  return useQuery<FeedbackStats>({
    queryKey: ['useFetchFeedbackStats'],
    queryFn: () => fetchResolve(`/feedback/stats`, getToken),
    initialData: {
      total: 0,
      done: {
        meets_checklist: 0,
        does_not_meet_checklist: 0,
      },
      missed: 0,
      checklistCompliance: 0,
      weekDetails: [],
      companyValuesAverage: {},
      companyValuesKeys: [],
    },
  })
}
