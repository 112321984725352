import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import posthog from 'posthog-js'
import * as Sentry from '@sentry/react'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'

import ReactQueryClientProvider from '@/ctxs/ReactQueryClientProvider'
import {
  MOCK_SERVER_ENABLED,
  POSTHOG_PUBLISHABLE_KEY,
  POSTHOG_API_HOST,
  SENTRY_DNS,
  IS_PRODUCTION,
  IS_DEV,
  TAWK_PROPERTY_ID,
  TAWK_WIDGET_ID,
} from '@/services/config/env'
import { router } from '@/router'
import '@/index.css'

// Initialize PostHog
if (!IS_DEV) {
  posthog.init(POSTHOG_PUBLISHABLE_KEY, {
    api_host: POSTHOG_API_HOST,
    person_profiles: 'always',
  })
}

// Sentry config
if (IS_PRODUCTION) {
  Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
  })
}

async function enableMocking() {
  if (!MOCK_SERVER_ENABLED) {
    return
  }

  const { worker } = await import('@/services/mocks/browser')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}

const TawkMessengerWrapper = () => {
  return (
    <TawkMessengerReact
      propertyId={TAWK_PROPERTY_ID}
      widgetId={TAWK_WIDGET_ID}
    />
  )
}

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <ReactQueryClientProvider>
        <RouterProvider router={router} />
        <TawkMessengerWrapper />
      </ReactQueryClientProvider>
    </React.StrictMode>,
  )
})
