import { useCallback, useMemo, useState } from 'react'
import moment from 'moment'

import { DataTable } from '@/components/ui/data-table'
import { H4, P } from '@/components/ui/typography'
import { Loading } from '@/components/ui/loading'
import { Button } from '@/components/ui/button'
import {
  EditObjectiveModal,
  NewObjectiveModal,
} from '@/components/objective-modal'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

import {
  useDeleteObjective,
  useFetchTeamObjectives,
} from '@/services/api/objective.api'

import {
  ObjectiveRow,
  generateColumns,
  generateRows,
} from './objectives.config'
import {
  Quarter,
  getIntervalsFromQuarter,
  getQuarterFromDate,
  parseQuarterString,
} from '@/services/utils/dates'

export const ObjectivesPage = () => {
  const [addNewFormOpen, setAddNewFormOpen] = useState(false)
  const [objectiveToEdit, setObjectiveToEdit] = useState<
    ObjectiveRow | undefined
  >(undefined)
  const [selectedQuarter, setSelectedQuarter] = useState<{
    label: string
    value: Quarter
  }>(getQuarterFromDate(moment().format()))
  const quarterIntervals = useMemo(
    () => getIntervalsFromQuarter(selectedQuarter.value),
    [selectedQuarter],
  )

  const { data: objectives, isLoading: isLoadingObjectives } =
    useFetchTeamObjectives(selectedQuarter.value)
  const { mutate: deleteObjective } = useDeleteObjective(selectedQuarter.value)

  const handleNewObjective = () => {
    setAddNewFormOpen(true)
  }

  const handleEditClick = (objective: ObjectiveRow) => {
    setObjectiveToEdit(objective)
  }

  const handleDeleteClick = useCallback(
    (objective: ObjectiveRow) => {
      deleteObjective(objective.id)
    },
    [deleteObjective],
  )

  const columns = useMemo(
    () => generateColumns(quarterIntervals, handleEditClick, handleDeleteClick),
    [quarterIntervals, handleDeleteClick],
  )
  const rows = useMemo(() => generateRows(objectives), [objectives])
  return (
    <div className="flex-1 rounded-2xl p-6 flex flex-col bg-white gap-4">
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-2">
          <H4 className="font-semibold">Objectives</H4>
          <P className="text-muted-foreground">
            Track and update your (or your team&apos;s) objectives.
          </P>
        </div>
        <Button onClick={handleNewObjective}>Add New Objective</Button>
      </div>
      <Select
        value={selectedQuarter.label}
        onValueChange={(value) => {
          setSelectedQuarter(parseQuarterString(value))
        }}
      >
        <SelectTrigger className="w-[180px]">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="2024 Q1">2024 Q1</SelectItem>
          <SelectItem value="2024 Q2">2024 Q2</SelectItem>
          <SelectItem value="2024 Q3">2024 Q3</SelectItem>
          <SelectItem value="2024 Q4">2024 Q4</SelectItem>
        </SelectContent>
      </Select>
      {isLoadingObjectives ? (
        <Loading />
      ) : (
        <DataTable columns={columns} data={rows} />
      )}
      <NewObjectiveModal
        isOpen={addNewFormOpen}
        setIsOpen={setAddNewFormOpen}
        intervals={quarterIntervals}
        selectedQuarter={selectedQuarter.value}
      />
      <EditObjectiveModal
        selectedObjective={objectiveToEdit}
        setSelectedObjective={setObjectiveToEdit}
        selectedQuarter={selectedQuarter.value}
      />
    </div>
  )
}
