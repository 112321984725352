import moment from 'moment'

export interface Interval {
  label: string
  weekNumber: number
  startDate: moment.Moment
}

export interface Quarter {
  year: number
  quarter: number
}

export function getIntervalsFromQuarter(quarter: Quarter): Interval[] {
  const quarterStart = moment({ year: quarter.year })
    .quarter(quarter.quarter)
    .startOf('quarter')
  const quarterEnd = moment({ year: quarter.year })
    .quarter(quarter.quarter)
    .endOf('quarter')
  const it = quarterStart.clone()

  const intervals: Interval[] = []
  while (it.week() <= quarterEnd.week()) {
    intervals.push({
      label: `W${it.week()}`,
      weekNumber: it.week(),
      startDate: it.clone().startOf('week'),
    })

    it.add(1, 'week')
  }

  return intervals
}

export function getQuarterFromDate(dateString: string): {
  label: string
  value: Quarter
} {
  const date = moment(dateString)
  const year = date.year()
  const quarter = date.quarter()

  return {
    label: `${year} Q${quarter}`,
    value: {
      year,
      quarter,
    },
  }
}

export function parseQuarterString(quarterString: string): {
  label: string
  value: Quarter
} {
  const year = Number(quarterString.split(' ')[0])
  const quarter = Number(quarterString.split(' ')[1][1])

  return {
    label: quarterString,
    value: {
      year,
      quarter,
    },
  }
}
