import { z } from 'zod'
import { useAuth } from '@clerk/clerk-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { TeamObjectiveSchema } from '@/lib/schema/objective-form.schema'

import {
  fetchResolve,
  postResolve,
  patchResolve,
  deleteResolve,
} from './helpers'

import { Quarter } from '../utils/dates'
import { Objective } from '@/types/Objective'

export const useFetchTeamObjectives = (quarter: Quarter) => {
  const { getToken } = useAuth()
  return useQuery<Objective[]>({
    queryKey: ['useFetchTeamObjectives', quarter],
    queryFn: () =>
      fetchResolve(
        `/team/objective?quarter=${quarter.quarter}&year=${quarter.year}`,
        getToken,
      ),
    initialData: [],
  })
}

export const useCreateObjective = (selectedQuarter: Quarter) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  return useMutation({
    mutationFn: async (body: z.infer<typeof TeamObjectiveSchema>) =>
      await postResolve('/team/objective', body, getToken),
    onSuccess: (data: Objective[]) => {
      queryClient.setQueryData(
        ['useFetchTeamObjectives', selectedQuarter],
        (oldData: Objective[]) => {
          return [...oldData, data]
        },
      )
    },
  })
}

export const usePatchObjective = (selectedQuarter: Quarter) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  return useMutation({
    mutationFn: async (props: {
      id: string
      body: z.infer<typeof TeamObjectiveSchema>
    }) =>
      await patchResolve(`/team/objective/${props.id}`, props.body, getToken),
    onSuccess: (data: Objective) => {
      queryClient.setQueryData(
        ['useFetchTeamObjectives', selectedQuarter],
        (oldData: Objective[]) =>
          oldData.map((objective) =>
            objective.id === data.id ? data : objective,
          ),
      )
    },
  })
}

export const useDeleteObjective = (selectedQuarter: Quarter) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  return useMutation({
    mutationFn: async (id: string) =>
      await deleteResolve(`/team/objective/${id}`, getToken),
    onSuccess: (data: Objective) => {
      queryClient.setQueryData(
        ['useFetchTeamObjectives', selectedQuarter],
        (oldData: Objective[]) =>
          oldData.filter((objective) => objective.id !== data.id),
      )
    },
  })
}
