import { PencilIcon } from 'lucide-react'
import { ColumnDef } from '@tanstack/react-table'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { DotsHorizontalIcon, TrashIcon } from '@radix-ui/react-icons'

import { Button } from '@/components/ui/button'
import { average } from '@/services/utils/math'
import { Interval } from '@/services/utils/dates'

import { Objective, ObjectiveItem } from '@/types/Objective'
import { toProperCase } from '@/services/utils/formatters'
import { sum } from 'lodash'
import { TeamMemberList } from '@/components/team-user-selector'

export type ObjectiveRow = Objective & {
  parsedValues: Record<number, ObjectiveItem['value']>
}

export const generateColumns = (
  intervals: Interval[],
  handleEditClick: (_: ObjectiveRow) => void,
  handleDeleteClick: (_: ObjectiveRow) => void,
): ColumnDef<ObjectiveRow>[] => [
  {
    accessorKey: 'title',
    header: 'Objective',
  },
  {
    accessorKey: 'owners',
    header: 'Owners',
    cell: ({ row }) => (
      <TeamMemberList className="w-8 h-8" users={row.original.owners ?? []} />
    ),
  },
  {
    accessorKey: 'type',
    header: 'Type',
    cell: ({ row }) => toProperCase(row.original.type),
  },
  {
    accessorKey: 'target',
    header: 'Target',
  },
  {
    accessorKey: 'hit',
    header: 'Hit',
  },
  ...(intervals.map(({ label, weekNumber }) => ({
    accessorKey: `parsedValues.${weekNumber}`,
    header: label,
  })) ?? []),
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => {
      const data = row.original

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <DotsHorizontalIcon className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => handleEditClick(data)}>
              <PencilIcon className="mr-2 h-4 w-4" />
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              className="text-red-400"
              onClick={() => handleDeleteClick(data)}
            >
              <TrashIcon className="mr-2 h-4 w-4" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
  },
]

export const generateRows = (data: Objective[]): ObjectiveRow[] => {
  return data.map((row) => {
    const filteredValues = row.values
      .filter((value) => value.value !== null)
      .map((value) => value.value!)

    return {
      ...row,
      parsedValues: {
        ...Object.fromEntries(
          row.values.map((value) => [value.weekNumber, value.value]),
        ),
      },
      hit: (row.type === 'sum'
        ? sum(filteredValues)
        : average(filteredValues)
      ).toFixed(0),
    }
  })
}
