import React, { useState, useMemo, useRef, useEffect } from 'react'
import { addDays, format } from 'date-fns'
import { Calendar as CalendarIcon, Printer, Star } from 'lucide-react'
import { DateRange } from 'react-day-picker'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { AvatarWithFallback } from '@/components/ui/avatar'
import { Dialog, DialogContent, DialogClose } from '@/components/ui/dialog'
import { H4, P, Muted, H3, H5 } from '@/components/ui/typography'
import { Separator } from '@/components/ui/separator'
import { Icon } from '@/components/ui/icon'
import { Loading } from '@/components/ui/loading'
import { useFetchFeedbackPoints } from '@/services/api/feedbackPoints.api'
import { FeedbackPointWithProfile } from '@/types/FeedbackPoint'
import { UserProfile } from '@/types/UserProfile'
import { useReactToPrint } from 'react-to-print'

const FeedbackModal = ({
  user,
  dateRange,
}: {
  user: UserProfile
  feedbackPoints: FeedbackPointWithProfile[]
  dateRange: DateRange | undefined
}) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [showScrollIndicator, setShowScrollIndicator] = useState(false)

  const checkScroll = () => {
    if (contentRef.current) {
      const isScrollable =
        contentRef.current.scrollHeight > contentRef.current.clientHeight
      setShowScrollIndicator(isScrollable)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      checkScroll()
      window.addEventListener('resize', checkScroll)
    }, 100)

    return () => {
      clearTimeout(timer)
      window.removeEventListener('resize', checkScroll)
    }
  }, [])

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    documentTitle: `Feedback Report for ${user.firstName} ${user.lastName}`,
    pageStyle: `
      @media print {
        @page {
          margin: 20mm; /* Adjust the margin as needed */
        }
      }
    `,
  })

  return (
    <>
      <div className="flex items-center gap-4 mb-4 pr-4">
        <AvatarWithFallback
          className="w-12 h-12"
          image={user.image}
          fallback={`${user.firstName[0]}${user.lastName[0]}`}
        />
        <div className="flex-1">
          <P className="font-semibold">{`${user.firstName} ${user.lastName}`}</P>
          <Muted>
            {dateRange?.from && dateRange.to
              ? `${format(dateRange.from, 'LLL dd, y')} - ${format(dateRange.to, 'LLL dd, y')}`
              : 'Date range not selected'}
          </Muted>
        </div>
        <Button onClick={handlePrint} variant="outline" size="sm">
          <Printer className="w-4 h-4 mr-2" />
          Print
        </Button>
      </div>
      <div ref={contentRef} className="overflow-y-auto flex-grow pr-4">
        <div className="grid gap-6">
          <div>
            <div className="space-y-4">
              <div>
                <H3>Highlights</H3>
                <ul className="mt-2 space-y-2 text-sm text-muted-foreground">
                  <li>- Exceeded quarterly sales targets by 20%</li>
                  <li>
                    - Implemented a new customer onboarding process that
                    improved retention by 15%
                  </li>
                  <li>
                    - Took on a leadership role in the company&apos;s diversity
                    and inclusion initiatives
                  </li>
                </ul>
              </div>
              <div>
                <H3>Lowlights</H3>
                <ul className="mt-2 space-y-2 text-sm text-muted-foreground">
                  <li>
                    - Struggled to meet deadlines on a few key projects due to
                    time management issues
                  </li>
                  <li>
                    - Could have communicated more effectively with the team
                    during a challenging period
                  </li>
                  <li>
                    - Needs to improve on delegating tasks and empowering the
                    team
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <H3>Value Alignment</H3>
            <div className="grid grid-cols-2 gap-6 mt-6">
              {[
                { name: 'Customer Obsession', score: 4 },
                { name: 'Be a Hustler', score: 3 },
                { name: 'Ownership', score: 5 },
                { name: 'Focus on Quick Wins', score: 3 },
                { name: 'Resourcefulness', score: 4 },
              ].map((value) => (
                <div key={value.name}>
                  <H5>{value.name}</H5>
                  <div className="flex items-center gap-2 mt-2">
                    <div className="flex gap-1">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <Star
                          key={star}
                          className={cn(
                            'w-5 h-5',
                            star <= value.score
                              ? 'fill-primary text-primary'
                              : 'fill-muted text-muted-foreground',
                          )}
                        />
                      ))}
                    </div>
                    <span className="text-sm text-muted-foreground">
                      {value.score}
                    </span>
                  </div>
                  <div className="mt-2 text-sm text-muted-foreground">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <H3>Approach for Feedback Conversation</H3>
            <div className="mt-2 space-y-4">
              <ul className="mt-2 space-y-2 text-sm text-muted-foreground">
                <li>
                  - Start with a positive note, highlighting the employee&apos;s
                  strengths and key achievements
                </li>
                <li>
                  - Discuss the areas for improvement in a constructive manner,
                  focusing on specific behaviors and their impact
                </li>
                <li>
                  - Encourage the employee to share their perspective and ideas
                  for professional development
                </li>
                <li>
                  - Collaborate on an action plan with clear, measurable goals
                  and a timeline for review
                </li>
                <li>
                  - Emphasize your commitment to the employee&apos;s growth and
                  success within the organization
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {showScrollIndicator && (
        <div className="absolute bottom-16 left-1/2 transform -translate-x-1/2">
          <Icon name="ChevronDown" className="animate-bounce" />
        </div>
      )}
    </>
  )
}

const UserFeedbackItem = ({
  user,
  feedbackPoints,
  dateRange,
}: {
  user: UserProfile
  feedbackPoints: FeedbackPointWithProfile[]
  dateRange: DateRange | undefined
}) => {
  const [isGenerating, setIsGenerating] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleGenerateClick = async () => {
    setIsGenerating(true)
    // Simulating API call
    await new Promise((resolve) => setTimeout(resolve, 1000))
    setIsGenerating(false)
    setIsModalOpen(true)
  }

  const feedbackCount = feedbackPoints.length

  return (
    <div className="flex items-center justify-between py-4">
      <div className="flex items-center space-x-4">
        <AvatarWithFallback
          className="h-12 w-12 border-2 border-primary"
          image={user.image}
          fallback={`${user.firstName[0]}${user.lastName[0]}`}
        />
        <div>
          <div className="font-medium">{`${user.firstName} ${user.lastName}`}</div>
          <p
            className={`line-clamp-1 pt-1 text-sm ${feedbackCount < 3 ? 'text-red-300' : 'text-green-500'} flex-grow `}
          >
            {feedbackCount < 3
              ? `Only ${feedbackCount} feedback point${feedbackCount > 1 ? 's' : ''} 🥺`
              : `${feedbackCount} feedback point${feedbackCount > 1 ? 's' : ''} 🚀`}
          </p>
        </div>
      </div>
      <Button onClick={handleGenerateClick} disabled={isGenerating}>
        {isGenerating ? 'Generating...' : isModalOpen ? 'Open' : 'Generate'}
      </Button>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="sm:max-w-[725px] max-h-[80vh] overflow-hidden flex flex-col">
          <FeedbackModal
            user={user}
            feedbackPoints={feedbackPoints}
            dateRange={dateRange}
          />
          <DialogClose asChild>
            <Button type="button" className="mt-4">
              Close
            </Button>
          </DialogClose>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export const FeedbackSessionsPage: React.FC = () => {
  const [date, setDate] = useState<DateRange | undefined>({
    from: addDays(new Date(), -90),
    to: new Date(),
  })

  const { data: feedbackPoints = [], isLoading } = useFetchFeedbackPoints()

  // Extract unique users from feedbackPoints
  const uniqueUsers = useMemo(() => {
    const userMap = new Map<string, UserProfile>()
    feedbackPoints.forEach((point) => {
      if (!userMap.has(point.email)) {
        userMap.set(point.email, {
          id: point.id,
          firstName: point.firstName,
          lastName: point.lastName,
          email: point.email,
          image: point.image,
          displayName: `${point.firstName} ${point.lastName}`,
        })
      }
    })
    return Array.from(userMap.values())
  }, [feedbackPoints])

  // Group feedbackPoints by user email
  const feedbackPointsByUser = useMemo(() => {
    const groupedFeedback: Record<string, FeedbackPointWithProfile[]> = {}
    feedbackPoints.forEach((point) => {
      if (!groupedFeedback[point.email]) {
        groupedFeedback[point.email] = []
      }
      groupedFeedback[point.email].push(point)
    })
    return groupedFeedback
  }, [feedbackPoints])

  return (
    <div className="flex-1 rounded-2xl p-6 flex flex-col bg-white">
      <div>
        <H4 className="font-semibold">Your Feedback Sessions</H4>
        <div className="flex justify-between mt-2">
          <P className="text-muted-foreground">
            Generate a report to be used in a feedback session (e.g.,
            performance review).
          </P>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                id="date"
                variant={'outline'}
                className={cn(
                  'w-[300px] justify-start text-left font-normal',
                  !date && 'text-muted-foreground',
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {date?.from ? (
                  date.to ? (
                    <>
                      {format(date.from, 'LLL dd, y')} -{' '}
                      {format(date.to, 'LLL dd, y')}
                    </>
                  ) : (
                    format(date.from, 'LLL dd, y')
                  )
                ) : (
                  <span>Pick a date</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="end">
              <Calendar
                initialFocus
                mode="range"
                defaultMonth={date?.from}
                selected={date}
                onSelect={setDate}
                numberOfMonths={2}
              />
            </PopoverContent>
          </Popover>
        </div>
      </div>

      {isLoading ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Loading />
        </div>
      ) : (
        <div className="pl-2">
          {uniqueUsers.length === 0 ? (
            <Muted className="my-16">No Feedback Sessions Available</Muted>
          ) : (
            uniqueUsers.map((user, index) => (
              <React.Fragment key={user.email}>
                <UserFeedbackItem
                  user={user}
                  feedbackPoints={feedbackPointsByUser[user.email]}
                  dateRange={date}
                />
                {index < uniqueUsers.length - 1 && <Separator />}
              </React.Fragment>
            ))
          )}
        </div>
      )}
    </div>
  )
}
