export const MOCK_SERVER_ENABLED =
  import.meta.env.VITE_MOCK_SERVER_ENABLED === 'true'
export const IS_PRODUCTION = !MOCK_SERVER_ENABLED && import.meta.env.PROD
export const IS_DEV = import.meta.env.DEV

export const POSTHOG_PUBLISHABLE_KEY = import.meta.env
  .VITE_POSTHOG_PUBLISHABLE_KEY
export const POSTHOG_API_HOST = import.meta.env.VITE_POSTHOG_API_HOST

export const SENTRY_DNS = import.meta.env.VITE_SENTRY_DNS

export const TAWK_PROPERTY_ID = import.meta.env.VITE_TAWK_PROPERTY_ID
export const TAWK_WIDGET_ID = import.meta.env.VITE_TAWK_WIDGET_ID