import { Skeleton } from '@/components/ui/skeleton'
import { cn } from '@/lib/utils'

export const Tile = ({
  children,
  className,
  isLoading,
}: {
  children: React.ReactNode[] | React.ReactNode
  className?: React.HTMLProps<HTMLElement>['className']
  isLoading?: boolean
}) => {
  return (
    <div
      className={cn(
        'flex flex-col gap-2 py-4 px-6 bg-white rounded-2xl',
        className,
      )}
    >
      {isLoading ? (
        <Skeleton className="h-full w-full rounded-2xl" />
      ) : (
        children
      )}
    </div>
  )
}
