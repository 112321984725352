import { useAuth } from '@clerk/clerk-react'
import { useQuery } from '@tanstack/react-query'

import { fetchResolve } from './helpers'

import { TeamUser } from '@/types/UserProfile'

export const useFetchTeamMembers = () => {
  const { getToken } = useAuth()
  return useQuery<TeamUser[]>({
    queryKey: ['useFetchTeamMembers'],
    queryFn: () => fetchResolve(`/team/user`, getToken),
    initialData: [],
  })
}
